/*  Grid System
  [Width.XXX_Large]: 1600,
  [Width.XX_Large]: 1440,
  [Width.X_Large]: 1279,
  [Width.Large]: 1024,
  [Width.X_Medium]: 992,
  [Width.Medium]: 768,
  [Width.Small]: 500,
  [Width.S_Small]: 320,
*/


.hide-x-large {
    @media (max-width: 1279px) {
        display: none!important;
        visibility: hidden;
    }
}

.visible-x-large {
    @media (min-width: 1280px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 1279px) {
        display: inherit;
        visibility: inherit;
    }
}

.hide-large {
    @media (max-width: 1024px) {
        display: none!important;
        visibility: hidden;
    }
}

.visible-large {
    @media (min-width: 1025px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 1024px) {
        display: inherit;
        visibility: inherit;
    }
}

.hide-x-medium {
    @media (max-width: 992px) {
        display: none!important;
        visibility: hidden;
    }
}

.visible-x-medium {
    @media (min-width: 993px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 992px) {
        display: inherit;
        visibility: inherit;
    }
}

.hide-medium {
    @media (max-width: 768px) {
        display: none!important;
        visibility: hidden;
    }
}

.visible-medium {
    @media (min-width: 769px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 768px) {
        display: inherit;
        visibility: inherit;
    }
}


.visible-small {
    @media (min-width: 501px) {
        display: none!important;
        visibility: hidden;
    }

    @media (max-width: 500px) {
        display: inherit;
        visibility: inherit;
    }
}

.hide-small {
    @media (max-width: 500px) {
        display: none!important;
        visibility: hidden;
    }
}
/*-- end Style Grid ---*/
