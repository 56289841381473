/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.overlay {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.ReactModalPortal * {
  outline: none;
}

.content {
  bottom: 0;
  top: 24px;
  position: fixed;
  left: 50%;
  overflow: hidden;
  right: auto;
  background-color: #fff;
  width: 100%;
  max-width: 962px;
  height: calc(100% - 24px);
  margin: 0 auto;
  padding: 0;
  outline: none;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.secondContent {
  height: inherit;
  max-height: 85%;
  top: 15%;
}

.centerContent.centerContent.centerContent.centerContent {
  left: 10px;
  right: 10px;
  top: 50%;
  bottom: auto;
  height: auto;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  width: auto;
  max-width: 376px;
}

.ReactModalPortal .ReactModal__Content {
  transition: transform 400ms ease-out 0.01ms;
  transform: translate3d(-50%, 100vh, 0);
  will-change: transform;
}

.ReactModalPortal .ReactModal__Content--after-open {
  transform: translate3d(-50%, 0, 0);
  will-change: transform;
}

.ReactModalPortal .ReactModal__Content--before-close {
  transform: translate3d(-50%, 100vh, 0);
  will-change: transform;
}

/** React Modal **/
.ReactModal__Body--open {
  overflow: hidden !important;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.ReactModal__Overlay {
  background-color: rgba(25, 0, 0, 0.2);
}

.ReactModal__Overlay--after-open {
  transition: background-color 0.2s ease;
  background-color: rgba(25, 0, 0, 0.55);
}

.ReactModal__Overlay--before-close {
  transition: background-color 0.2s ease 0.2s;
  background-color: rgba(25, 0, 0, 0);
}
/** end React Modal **/

/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

/** Address **/

.address-modal .ReactModal__Content {
  border-radius: 24px;
  max-height: 632px;
  max-width: 632px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  transform: translate3d(0%, 110%, 0);

  @media (max-width: 767px) {
    top: 24px;
    height: calc(100% - 24px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 100%;
  }
}

.address-modal .ReactModal__Content--after-open {
  transform: translate3d(0, 0, 0);
  transition: transform 400ms ease-in-out 0.01ms;
}

.address-modal .ReactModal__Content--before-close {
  transform: translate3d(0%, 110%, 0);
  transition: transform 400ms ease-in-out 0.01ms;
}

/** Auth Modal **/
.auth-modal .ReactModal__Content {
  transform: translate3d(0, 100%, 0);
  will-change: transform;
}

.auth-modal .ReactModal__Content--after-open {
  transition: transform 400ms ease-in-out 0.01ms;
  transform: translate3d(0, -50%, 0);
  will-change: transform;
}

.auth-modal .ReactModal__Content--before-close {
  transition: transform 400ms ease-in-out 0.01ms;
  transform: translate3d(0, 100%, 0);
  will-change: transform;
}
/** end Auth Modal **/

/** Product Detail **/
.product-detail .ReactModal__Content {
  @media(min-width: 768px) {
    transform: translate3d(-100%, 0, 0);
    will-change: transform;
  }
}

.product-detail .ReactModal__Content--after-open {
  @media(min-width: 768px) {
    transform: translate3d(0%, 0, 0);
    transition: transform 400ms ease-in-out 0.01ms;
    will-change: transform;
  }
}

.product-detail .ReactModal__Content--before-close {
  @media(min-width: 768px) {
    transform: translate3d(-100%, 0, 0);
    transition: transform 400ms ease-in-out 0.01ms;
    will-change: transform;
  }
}

.product-detail .product-detail-content {
  @media (min-width: 768px) {
    height: 100%;
    left: 0;
    top: 0;
    max-width: 656px;
    border-radius: 0;
  }
}

/** end Product Detail **/

/** Product tags **/

.product-tags-modal .product-tags-modal-content {
  @media (max-width: 767px) {
    max-height: calc(100% - 24px);
    bottom: 0;
    top: auto;
  }

  @media (min-width: 768px) {
    border-radius: 12px;
    max-width: 640px;
    max-height: 368px;
    top: 50%;
  }
}

.product-tags-modal .ReactModal__Content--after-open {
  @media (min-width: 768px) {
    transform: translate3d(-50%, -50%, 0);
    transition: transform 400ms ease-in-out 0.01ms;
    will-change: transform;
  }
}

.product-tags-modal .ReactModal__Content--before-close {
  @media (min-width: 768px) {
    transform: translate3d(-50%, 100%, 0);
    transition: transform 400ms ease-in-out 0.01ms;
    will-change: transform;
  }
}

/** end Product tags **/


/** App Modal **/
.app-modal .ReactModal__Content {
  @media(min-width: 768px) {
    transform: translate3d(-50%, 100%, 0);
    will-change: transform;
  }
}

.app-modal .ReactModal__Content--after-open {
  @media(min-width: 768px) {
    transition: transform 400ms ease-in-out 0.01ms;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
}

.app-modal .ReactModal__Content--before-close {
  @media(min-width: 768px) {
    transform: translate3d(-50%, 0, 0);
    transition: transform 400ms ease-in-out 0.01ms;
    will-change: transform;
  }
}

.app-modal .app-modal-content {
  top: auto;
  height: auto;
  @media (min-width: 768px) {
    left: 50%;
    top: 50%;
    height: 500px;
    box-shadow: 0 0 1px rgb(0 0 0 / 25%), 0 2px 4px rgb(0 0 0 / 12%);
    max-width: 600px;
    border-radius: 16px;
  }
}

/** end App Modal **/