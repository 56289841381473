html body {
  --content-bottom-offset: 48px;
  --content-side-offset: 24px;
  --content-top-offset: 32px;
  -webkit-font-smoothing: antialiased;
  @media (--mediumDown) {
    & {
      --content-top-offset: 16px;
      --content-bottom-offset: 40px;
      --content-side-offset: 12px;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

#__next {
}

.ReactModal__Overlay {
  z-index: 500;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  user-select: none;
}

textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  appearance: none;
  -webkit-appearance: none;
}

button,
button:focus {
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
